<template>
  <div class="page entry custom-messages-index">
    <Suspense>
      <template #default>
        <LazyCustomMessagesList
          v-if="activeComponent === CustomMessageComponentsEnum.List"
          :active-component="activeComponent"
          :list-key="listKey"
          @set-active="setActiveComponent"
        />
        <LazyCustomMessagesForm
          v-else-if="
            activeComponent === CustomMessageComponentsEnum.Form
          "
          :active-component="activeComponent"
          @set-active="setActiveComponent"
          @sent-message="handleSentMessage"
        />
      </template>
      <template #fallback>
        <Loader class="entry componentframescompany loader" />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { CustomMessageComponentsEnum } from '@/types/CustomMessagesEnum';

const activeComponent = ref(CustomMessageComponentsEnum.List);

const setActiveComponent = (
  component: CustomMessageComponentsEnum,
) => {
  activeComponent.value = component;
};

const listKey = ref(Date.now());
const handleSentMessage = async () => {
  listKey.value = Date.now();
  await navigateTo('custom-messages/sent_messages');
};
</script>

<style lang="scss" scoped>
.page.entry.custom-messages-index {
  width: 100%;
  height: calc(100vh - 60px);
}
</style>
